define("discourse/plugins/poll/discourse/components/poll-breakdown-chart", ["exports", "@ember/component", "@ember/object/computed", "@ember/runloop", "@ember/template", "@ember-decorators/component", "discourse/lib/decorators", "discourse-i18n", "discourse/plugins/poll/lib/chart-colors", "discourse/plugins/poll/discourse/components/modal/poll-ui-builder", "@ember/template-factory"], function (_exports, _component, _computed, _runloop, _template, _component2, _decorators, _discourseI18n, _chartColors, _pollUiBuilder, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PollBreakdownChart = dt7948.c(class PollBreakdownChart extends _component.default {
    // Arguments:
    group = null;
    options = null;
    displayMode = null;
    highlightedOption = null;
    setHighlightedOption = null;
    static #_ = (() => dt7948.g(this.prototype, "data", [(0, _computed.mapBy)("options", "votes")]))();
    #data = (() => (dt7948.i(this, "data"), void 0))();
    _optionToSlice = null;
    _previousHighlightedSliceIndex = null;
    _previousDisplayMode = null;
    init() {
      super.init(...arguments);
      this._optionToSlice = {};
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this._chart) {
        this._chart.destroy();
      }
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      const canvas = this.element.querySelector("canvas");
      this._chart = new window.Chart(canvas.getContext("2d"), this.chartConfig);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      if (this._chart) {
        this._updateDisplayMode();
        this._updateHighlight();
      }
    }
    colorStyle(optionColors, index) {
      return (0, _template.htmlSafe)(`background: ${optionColors[index]};`);
    }
    static #_2 = (() => dt7948.n(this.prototype, "colorStyle", [(0, _decorators.default)("optionColors", "index")]))();
    chartConfig(data, displayMode) {
      const transformedData = [];
      let counter = 0;
      this._optionToSlice = {};
      data.forEach((votes, index) => {
        if (votes > 0) {
          transformedData.push(votes);
          this._optionToSlice[index] = counter++;
        }
      });
      const totalVotes = transformedData.reduce((sum, votes) => sum + votes, 0);
      const colors = (0, _chartColors.getColors)(data.length).filter((color, index) => data[index] > 0);
      return {
        type: _pollUiBuilder.PIE_CHART_TYPE,
        plugins: [window.ChartDataLabels],
        data: {
          datasets: [{
            data: transformedData,
            backgroundColor: colors,
            // TODO: It's a workaround for Chart.js' terrible hover styling.
            // It will break on non-white backgrounds.
            // Should be updated after #10341 lands
            hoverBorderColor: "#fff"
          }]
        },
        options: {
          plugins: {
            tooltip: false,
            datalabels: {
              color: "#333",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              borderRadius: 2,
              font: {
                family: getComputedStyle(document.body).fontFamily,
                size: 16
              },
              padding: {
                top: 2,
                right: 6,
                bottom: 2,
                left: 6
              },
              formatter(votes) {
                if (displayMode !== "percentage") {
                  return votes;
                }
                const percent = _discourseI18n.default.toNumber(votes / totalVotes * 100.0, {
                  precision: 1
                });
                return `${percent}%`;
              }
            }
          },
          responsive: true,
          aspectRatio: 1.1,
          animation: {
            duration: 0
          },
          // wrapping setHighlightedOption in next block as hover can create many events
          // prevents two sets to happen in the same computation
          onHover: (event, activeElements) => {
            if (!activeElements.length) {
              (0, _runloop.next)(() => {
                this.setHighlightedOption(null);
              });
              return;
            }
            const sliceIndex = activeElements[0].index;
            const optionIndex = Object.keys(this._optionToSlice).find(option => this._optionToSlice[option] === sliceIndex);
            (0, _runloop.next)(() => {
              this.setHighlightedOption(Number(optionIndex));
            });
          }
        }
      };
    }
    static #_3 = (() => dt7948.n(this.prototype, "chartConfig", [(0, _decorators.default)("data", "displayMode")]))();
    _updateDisplayMode() {
      if (this.displayMode !== this._previousDisplayMode) {
        const config = this.chartConfig;
        this._chart.data.datasets = config.data.datasets;
        this._chart.options = config.options;
        this._chart.update();
        this._previousDisplayMode = this.displayMode;
      }
    }
    _updateHighlight() {
      const activeElements = [];
      if (this.highlightedOption) {
        const index = this._optionToSlice[this.highlightedOption];
        if (index !== undefined) {
          activeElements.push({
            datasetIndex: 0,
            index
          });
        }
      }
      this._chart.setActiveElements(activeElements);
      this._chart.update();
    }
    static #_4 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <label class="poll-breakdown-chart-label">{{@group}}</label>
        <canvas class="poll-breakdown-chart-chart"></canvas>
      
    */
    {
      "id": "2rTPcVDV",
      "block": "[[[1,\"\\n    \"],[10,\"label\"],[14,0,\"poll-breakdown-chart-label\"],[12],[1,[30,1]],[13],[1,\"\\n    \"],[10,\"canvas\"],[14,0,\"poll-breakdown-chart-chart\"],[12],[13],[1,\"\\n  \"]],[\"@group\"],false,[]]",
      "moduleName": "/home/pacs/vcg01/users/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/poll/discourse/components/poll-breakdown-chart.js",
      "isStrictMode": true
    }), this))();
  }, [(0, _component2.classNames)("poll-breakdown-chart-container")]);
  var _default = _exports.default = PollBreakdownChart;
});